.admin-container {
   width: 100% !important;
   border: 5px solid red !important;
}
.admin-dialog {
    padding: 20px
}
.admin-dialog-text {
    width: 300px
}
.App-textarea {
    margin: 20px !important;
    width: 800px !important;
}
.App-textarea-small {
    margin: 20px !important;
    width: 200px !important;
}
  
.App-icon-spacing {
    margin-right: 10px !important;
}
.App-icon-cursor {
    cursor: pointer !important;
}
.button {
    
}
.chart-edit-help {
    margin-top: 10px;
}
.MuiDataGrid-columnHeaderTitle {
    font-weight: bold;
}
.internal {
    background-color: #eeeeee; padding: 20px;
}
.modal-button {
    margin: 10px;
}
.modal-title {
    font-weight: bold;
    font-size: 16px;   
}
