
@import url('https://fonts.googleapis.com/css2?family=Bitter:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');

.admin-main {
    margin-top: 70px;
}

.dashboard-health-section{
    margin-top: 20px;
}

#indicators {
   margin-top: 20px; 
}

.indicator-heading {
    color: #378f82;
    font-family: 'Nunito';
    font-size: 22px;
    font-weight: bold;
}

.indicator-iframe-heading {
    color: #378f82;
    font-family: 'Nunito';
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
}

.indicator-iframe-heading-link {
    color: #378f82;
    font-family: 'Nunito';
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 20px;
    text-decoration: none;
}

.subcategory-link {
    cursor: grab;
    margin-top: 10px;
    color: #378f82
}
.subcategory-link-active {
    cursor: grab;
    margin-top: 10px;
    color: #ED7330;
}
.subcategory-heading {
    margin-top: 10px;
    font-family: 'Nunito'
}

.topic-grid {
    border-right: 1px solid #dddddd;
}

.topic-link {
    cursor: grab;
    margin-top: 10px;
    color: #378f82;
    font-family: 'Nunito'
}
.topic-link-active {
    color: #ED7330;
    cursor: grab;
    margin-top: 10px;
    font-family: 'Nunito';
}

.dashboard-container {
    background-color: white;
    border: 1px solid #dddddd;
    border-radius: 15px;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 30px;
    padding-bottom: 50px;
}

.indicator-frame {
    border: 0
}

.vecap-icon {
    font-size: 40px
}

.vecap-bold {
    font-weight: bold;
}

.vecap-container-heading {
    font-family: 'Bitter';
    font-size: 40px;
    margin-bottom: 20px;
}

.vecap-container-row {
    display: flex; /* equal height of the children */
  }

.vecap-container-subheading {
    font-family: 'Nunito';
    font-size: 22px;
    margin-bottom: 20px;
}

.vecap-container-text {
    font-family: 'Nunito';
    font-size: 18px
    
}

.vecap-link {
    color: #0C6755;
    margin-bottom: 5px;
    cursor: pointer
}

.vecap-link:hover {
    color: #ED7330;
    cursor: pointer
}

.vecap-list {
    margin-bottom: 20px;
}